import React, { useCallback, useMemo, useRef, useState } from 'react';
import { AxiosError } from 'axios';

import { useMountedState } from 'react-use';
import {
  FunctionReturningPromise,
  PromiseType,
} from 'react-use/lib/misc/types';
import { toast } from 'react-toastify';

// import { ERROR_MESSAGE_GENERIC } from '../constants/common';
// import { useToast } from './useToast';

const ERROR_MESSAGE_GENERIC = 'An error has occurred.';

export type AsyncState<T> =
  | {
      loading: boolean;
      error?: undefined;
      value?: undefined;
    }
  | {
      loading: true;
      error?: Error | undefined;
      value?: T;
    }
  | {
      loading: false;
      error: Error;
      value?: undefined;
    }
  | {
      loading: false;
      error?: undefined;
      value: T;
    };

export type UseRequestProps<T extends FunctionReturningPromise> = {
  fn: T;
  onSuccess?: () => void;
  onError?: (error: AxiosError) => void;
  initialState?: StateFromFunctionReturningPromise<T>;
};

type StateFromFunctionReturningPromise<T extends FunctionReturningPromise> =
  AsyncState<PromiseType<ReturnType<T>>>;

export type AsyncFnReturn<
  T extends FunctionReturningPromise = FunctionReturningPromise
> = [T, StateFromFunctionReturningPromise<T>];

/*
 * This is a fork of react-use/useAsyncFn
 * The reason for the fork are missing callbacks - onSuccess, onError, which
 * are very useful for our cse
 */
export const useRequest = <T extends FunctionReturningPromise>({
  fn,
  onSuccess,
  onError,
  initialState = { loading: false },
}: UseRequestProps<T>): AsyncFnReturn<T> => {
  const lastCallId = useRef(0);
  const isMounted = useMountedState();
  const [state, set] =
    useState<StateFromFunctionReturningPromise<T>>(initialState);

  const callback = useCallback(
    (...args: Parameters<T>): ReturnType<T> => {
      const callId = ++lastCallId.current;
      set((prevState) => ({ ...prevState, loading: true }));

      return fn(...args).then(
        (value) => {
          if (isMounted() && callId === lastCallId.current) {
            set({ value, loading: false });
            onSuccess?.();
          }

          return value;
        },
        (error: AxiosError) => {
          if (isMounted() && callId === lastCallId.current) {
            set({ error, loading: false });
            onError?.(error);
          }

          return error;
        }
      ) as ReturnType<T>;
      // TODO: Investigate memo of deps, resp. explicit deps
    },
    [fn, onSuccess, onError, isMounted]
  );

  return [callback as unknown as T, state];
};

// TODO: Proper typing of errors
export const formatGenericErrorMessage = (
  error?: AxiosError<{ message: string }>
) => {
  const djangoError = JSON.parse(error?.request?.response || null);

  // Django validation error
  if (djangoError) {
    if (djangoError?.exceptionCode === 'invalid') {
      const { ...validationErrors } = djangoError;

      return (
        <div>
          Form validation error:
          <ul className="mt-2 space-y-1">
            {/* Since there is no map() for objects, we have to hack it by making an array out of object keys,
            and then get the values by the keys. */}
            {Object.keys(validationErrors.errors).map(
              (fieldName: string, index: number) => {
                const fieldErrors: string[] =
                  validationErrors.errors[fieldName];

                return (
                  <li key={index}>
                    {/*
                     * Note: TailwindCSS has it's own implementation of bullets via pseudo-elements in "list-disc"
                     * However it's miss-aligned in toast container. That's why we use "•" character here the character here.
                     */}
                    • <strong className="font-semibold">{fieldName}</strong>:{' '}
                    {fieldErrors.join(', ')}
                  </li>
                );
              }
            )}
          </ul>
        </div>
      );
    }
  }

  const message =
    djangoError?.detail ?? // General Django error
    error?.response?.data?.message ?? // Github error
    error?.message; // Generic error

  return 'An error occurred: ' + (message ? message : ERROR_MESSAGE_GENERIC);
};

export type UseRequestAndNotifyProps<T extends FunctionReturningPromise> = Omit<
  UseRequestProps<T>,
  'onSuccess' | 'onError'
> & {
  // onSuccess === string => use it as a message
  // onSuccess === function => invoke  the function
  onSuccess?: UseRequestProps<T>['onSuccess'] | string;
  // Same as onSuccess
  onError?: UseRequestProps<T>['onError'] | string;
};

// Request with optional success notification and mandatory (ootb) failure notification
export const useRequestAndNotify = <T extends FunctionReturningPromise>({
  onSuccess,
  onError,
  ...props
}: UseRequestAndNotifyProps<T>) => {
  const onSuccessAndNotify = useCallback(() => {
    // onSuccess === string => render success toast
    if (typeof onSuccess === 'string') {
      toast.success(onSuccess);
      // otherwise invoke the function
    } else {
      onSuccess?.();
    }
  }, [onSuccess]);

  const onErrorAndNotify = useCallback(
    (error: AxiosError) => {
      // onError === string => render custom error toast
      if (typeof onError === 'string') {
        toast.error(onError);

        // onError === string => render error toast with generic message extracted from server response
      } else if (onError === undefined) {
        toast.error('An error has occurred.');

        // otherwise invoke the function
      } else {
        onError?.(error);
      }
    },
    [onError]
  );

  const enhancedProps = useMemo(
    () => ({
      ...props,
      onSuccess: onSuccessAndNotify,
      onError: onErrorAndNotify,
    }),
    [props, onSuccessAndNotify, onErrorAndNotify]
  );

  return useRequest(enhancedProps);
};
