import classNames from "classnames";
import { IconContext } from "react-icons";
import { AiFillStar, AiOutlineStar } from "react-icons/ai";

type StarsProps = {
  value: number;
  onChange: React.Dispatch<React.SetStateAction<number>>;
  className?: string;
};

export const Stars = ({ value, onChange, className }: StarsProps) => {
  return (
    <IconContext.Provider
      value={{
        style: { verticalAlign: "middle", fontSize: "4rem" },
        className: "text-yellow",
      }}
    >
      <div className={classNames("flex flex-row justify-between", className)}>
        {[1, 2, 3, 4, 5].map((starId) => (
          <button key={starId} onClick={() => onChange(starId)}>
            {starId > value ? <AiOutlineStar /> : <AiFillStar />}
          </button>
        ))}
      </div>
    </IconContext.Provider>
  );
};
