import { RudsRequest } from "..";
import { Rating } from "../../helpers/stars";

export type SendReviewProps = {
  objectId: string;
  niceNumber: string;
  rating?: Rating;
  email?: string;
  feedback?: string;
};

export type SendReviewResponse = string;

export const sendReview = async ({
  objectId,
  niceNumber,
  rating,
  email,
  feedback,
}: SendReviewProps) =>
  RudsRequest().post<SendReviewResponse>("/customer_feedback", {
    entities: [{ objectId, niceNumber, rating, email, feedback }],
  });
