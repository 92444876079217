import axios from "axios";
import { useSWRRequest, APIModuleHook } from "./useSWRRequest";
import env from "react-dotenv";

export const useRudsRequest: APIModuleHook = (request, config, swrKey?) => {
  const SmartooRequestInstance = RudsRequest();
  return useSWRRequest(request, SmartooRequestInstance, config, swrKey);
};

export const RudsRequest = () => {
  const API_URL = env.API_URL;
  const UNIVERSAL_DEVICE_TOKEN = env.UNIVERSAL_DEVICE_TOKEN;

  const axiosInstance = axios.create({
    baseURL: API_URL,
    headers: {
      'X-LepsiPokladna-DeviceToken': UNIVERSAL_DEVICE_TOKEN,
    }
  });

  axiosInstance.interceptors.request.use(async (request) => {
    request.headers["Content-Type"] = "application/json";
    return request;
  });

  return axiosInstance;
};
