export type Rating = "EXCELLENT" | "GREAT" | "GOOD" | "BAD" | "AWFUL";

export const getRatingFromNumber = (stars: number): Rating | undefined => {
  switch (stars) {
    case 1:
      return "AWFUL";
    case 2:
      return "BAD";
    case 3:
      return "GOOD";
    case 4:
      return "GREAT";
    case 5:
      return "EXCELLENT";
  }
};
